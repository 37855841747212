<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Proyecciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Proyecciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <fieldset class="well card ">
              <legend
                class="well-legend text-bold bg-navy text-light rounded-bottom shadow"
              >
                <span class="badge">{{ accion }} Proyección</span>
              </legend>
              <div class="col-md-12">
                <div class="row">
                  <div class="row col-md-8">
                    <div
                      :class="
                        form.id ? 'form-group col-md-6' : 'form-group col-md-12'
                      "
                    >
                      <label for="bloque">Bloque</label>
                      <v-select
                        class="form-control form-control-sm p-0 shadow"
                        placeholder="Bloque"
                        label="nombre"
                        v-model="bloque"
                        :options="listasForms.bloques"
                        @input="selectBloque"
                        :disabled="form.id != null"
                        :class="[
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                          $v.form.bloque_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                        ]"
                      >
                      </v-select>
                      <div style="color: red"></div>
                    </div>
                    <div v-if="form.id" class="form-group col-md-6">
                      <div class="col-md-12 form-group">
                        <label for="estado">Estado</label>
                        <select
                          class="form-control form-control-sm shadow"
                          v-model="form.estado"
                          :disabled="bloqueado"
                          :class="
                            $v.form.estado.$invalid ? 'is-invalid' : 'is-valid'
                          "
                        >
                          <option value="">Seleccionar...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="email">Fecha Inicial</label>
                      <input
                        style="min-width: 40px;"
                        type="date"
                        class="form-control shadow"
                        id="fecha_ini"
                        v-model="form.fecha_ini"
                        @input="validarFechas"
                        :disabled="bloqueado"
                        :class="
                          $v.form.fecha_ini.$invalid ? 'is-invalid' : 'is-valid'
                        "
                      />
                      <div style="color: red"></div>
                    </div>
                    <div class="form-group col-md-6">
                      <div class="col-md-12 form-group">
                        <label for="fecha_fin">Fecha Final</label>
                        <input
                          type="date"
                          class="form-control shadow"
                          id="fecha_fin"
                          v-model="form.fecha_fin"
                          :disabled="bloqueado"
                          @input="validarFechas"
                          :class="
                            $v.form.fecha_fin.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div>
                      <label for="clase_vehiculo">Observaciones</label>
                      <textarea
                        :disabled="bloqueado"
                        class="form-control shadow"
                        v-model="form.observacion"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <button
                  type="button"
                  class="btn bg-gradient-secondary col-md-1 shadow-lg"
                  @click="back"
                >
                  <i class="fas fa-reply"></i><br />Volver
                </button>
                <button
                  type="button"
                  class="btn bg-gradient-primary col-md-1 ml-1 shadow-lg"
                  @click="save"
                  v-if="
                    $store.getters.can(
                      'hidrocarburos.proyeccionHidrocarburos.create' ||
                        'hidrocarburos.proyeccionHidrocarburos.edit'
                    )
                  "
                  v-show="!$v.form.$invalid && !bloqueado"
                >
                  <i class="fas fa-paper-plane"></i>
                  <div>Guardar</div>
                </button>
              </div>
            </fieldset>
          </div>
          <div class="container-fluid" v-if="form.id && proyeccion.bloque">
            <fieldset class="well card">
              <legend
                class="well-legend text-bold bg-navy text-light rounded-bottom shadow"
              >
                <span class="badge">Detalles Proyecciones</span>
              </legend>

              <div
                class="card-body p-0"
                v-for="(sitio, index) in proyeccion.bloque.sitios"
                :key="index + sitio.id"
              >
                <div class="col-md-6 col-lg-12 col-xs-12">
                  <div class="card card-info card-outline shadow">
                    <div class="card-header pt-2 pb-2">
                      <i class="fas fa-map-signs fa-lg"></i>
                      <b> SITIO: {{ sitio.nombre }}</b>
                      <b class="pl-3" colspan="2" rowspan="2">
                        Api:
                        {{
                          promedios
                            .find((promedio) => promedio.sitio_id === sitio.id)
                            ?.api.toFixed(2)
                        }}
                        %S:
                        {{
                          promedios
                            .find((promedio) => promedio.sitio_id === sitio.id)
                            ?.azufre.toFixed(2)
                        }}
                      </b>

                      <div class="card-tools">
                        <button
                          type="button"
                          class="btn btn-sm-2 bg-gradient-success shadow"
                          v-show="!bloqueado"
                          @click="saveDetalle(sitio.id)"
                          v-if="
                            $store.getters.can(
                              'hidrocarburos.proyeccionHidrocarburos.saveDetalles'
                            )
                          "
                        >
                          <i class="fas fa-save"></i>
                        </button>

                        <button
                          type="button"
                          data-card-widget="collapse"
                          data-toggle="tooltip"
                          title="Collapse"
                          class="btn btn-tool"
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body show">
                      <div
                        class="card card-outline alert-default-primary mr-3 ml-3 shadow-lg"
                        v-for="(producto, index) in sitio.productos_liquidos"
                        :key="index + producto.id"
                      >
                        <div class="card-header ">
                          <i class="fas fa-truck-loading fa-lg"></i>
                          <b> PRODUCTO: {{ producto.nombre }}</b>
                          <b class="pl-3" colspan="2" rowspan="2">
                            Api: {{ producto.api }} %S: {{ producto.azufre }}
                          </b>

                          <div class="card-tools">
                            <button
                              type="button"
                              data-card-widget="collapse"
                              data-toggle="tooltip"
                              title="Collapse"
                              class="btn btn-tool"
                            >
                              <i class="fas fa-minus"></i>
                            </button>
                          </div>
                        </div>

                        <div class="card-body table-responsive pb-2 show">
                          <ProyeccionHidrocarburosDetalleForm
                            ref="ProyeccionHidrocarburosDetalleForm"
                            :productoProp="producto"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import vSelect from "vue-select";
import moment from "moment";
import ProyeccionHidrocarburosDetalleForm from "./ProyeccionHidrocarburosDetalleForm";
import Loading from "../../../../components/Loading";
import { required } from "vuelidate/lib/validators";

export default {
  name: "ProyeccionesHidrocarburosForm",
  components: {
    vSelect,
    Loading,
    ProyeccionHidrocarburosDetalleForm,
  },
  data() {
    return {
      accion: null,
      cargando: false,
      bloqueado: false,
      proyeccion: [],
      indexFocus: null,
      sitioFocus: null,
      tipoInput: null,
      listasForms: {},
      check_active: false,
      promedios: [],
      form: {
        id: null,
        bloque_id: null,
        fecha_ini: null,
        fecha_fin: null,
        estado: null,
        observacion: null,
      },
      bloque: {},
      total_dias: null,
      operacionDetalles: [],
    };
  },

  validations() {
    if (!this.form.id) {
      return {
        form: {
          bloque_id: {
            required,
          },
          fecha_ini: {
            required,
          },
          fecha_fin: {
            required,
          },
        },
      };
    } else {
      return {
        form: {
          bloque_id: {
            required,
          },
          fecha_ini: {
            required,
          },
          fecha_fin: {
            required,
          },
          estado: {
            required,
          },
        },
      };
    }
  },

  methods: {
    async init() {
      this.cargando = true;
      this.accion = this.accion ? this.accion : this.$route.params.accion;
      let proyeccion_id = this.form.id ? this.form.id : this.$route.params.id;
      if (this.accion == "Editar") {
        await axios
          .get(
            "/api/hidrocarburos/proyeccionHidrocarburos?proyeccion_id=" + 1,
            {
              params: { proyeccion_id: proyeccion_id },
            }
          )
          .then(async (response) => {
            this.proyeccion = await response.data;
            this.bloqueado = this.proyeccion.estado == 2 ? true : false;
            this.form = {
              id: this.proyeccion.id,
              bloque_id: this.proyeccion.bloque_id,
              fecha_ini: this.proyeccion.fecha_ini,
              fecha_fin: this.proyeccion.fecha_fin,
              estado: this.proyeccion.estado,
              observacion: this.proyeccion.observacion,
            };

            this.bloque = this.listasForms.bloques.filter((bloque) => {
              return bloque.id === this.proyeccion.bloque_id;
            });

            await this.calcularCantidad();
            this.proyeccion.bloque.sitios.forEach((sitio) => {
              let promedio = this.calcularPromedios(sitio);
              this.promedios.push(promedio);
            });
          });
      } else {
        this.form.estado = 1;
      }
      this.cargando = false;
    },

    async save() {
      let me = this;
      me.cargando = true;
      if (me.form.estado == 2) {
        me.$swal({
          title: "Esta seguro de guardar la proyección?",
          text:
            "Se guardarán todos los detalles de todos los sitios del bloque " +
            me.bloque[0].nombre +
            "!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Guardar!",
        }).then(async (result) => {
          if (result.value) {
            await axios
              .post("/api/hidrocarburos/proyeccionHidrocarburos", {
                form: me.form,
                detalles: me.operacionDetalles,
              })
              .then(async (response) => {
                me.accion = "Editar";
                me.form.id = await response.data.id;
                me.form.estado = await response.data.estado;
                me.proyeccion = await response.data;

                await me.init();
                await me.$swal({
                  icon: "success",
                  title: "Se guardó la proyección exitosamente...",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              });
          }
        });
      } else {
        await axios
          .post("/api/hidrocarburos/proyeccionHidrocarburos", me.form)
          .then(async (response) => {
            me.accion = "Editar";
            me.form.id = await response.data.id;
            me.form.estado = await response.data.estado;
            me.proyeccion = await response.data;

            await me.init();
            await this.$swal({
              icon: "success",
              title: "Se guardó la proyección exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }

      me.cargando = false;
    },

    async saveDetalle(sitio_id) {
      this.cargando = true;
      let detalles = this.operacionDetalles.filter((detalle) => {
        return detalle.sitio_id == sitio_id;
      });

      await axios
        .post("/api/hidrocarburos/proyeccionHidrocarburos/detalles", {
          sitio_id: sitio_id,
          detalles: detalles,
        })
        .then(async (response) => {
          this.operacionDetalles.filter((detalle, index) => {
            response.data.filter((detResp) => {
              if (
                detalle.producto_liquido_id == detResp.producto_liquido_id &&
                detalle.fecha == detResp.fecha
              ) {
                this.operacionDetalles[index].id = detResp.id;
              }
            });
          });
          this.$swal({
            icon: "success",
            title: "Se guardaron los detalles del sitio exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      this.cargando = false;
    },

    async calcularCantidad() {
      this.operacionDetalles = [];
      if (this.form.fecha_ini && this.form.fecha_fin) {
        const fechaIni = new Date(this.form.fecha_ini);
        const fechaFin = new Date(this.form.fecha_fin);
        let dias = [];
        while (fechaIni <= fechaFin) {
          fechaIni.setDate(fechaIni.getDate() + 1);
          dias.push(fechaIni.toLocaleDateString("es-ES"));
          this.proyeccion.bloque.sitios.forEach((sitio) => {
            if (sitio.productos_liquidos) {
              sitio.productos_liquidos.forEach((producto) => {
                this.operacionDetalles.push({
                  id: null,
                  proyeccion_id: this.proyeccion.id,
                  fecha: moment(fechaIni).format("YYYY-MM-DD"),
                  producto_liquido_id: producto.id,
                  sitio_id: producto.sitio_id,
                  crudo_ventas: null,
                  planta_externa: null,
                  regalias: null,
                  inventario: null,
                });
              });
            }
          });
        }

        this.total_dias = dias;
        if (this.proyeccion.det_proyecciones.length > 0) {
          this.operacionDetalles.filter((detalle, index) => {
            this.proyeccion.det_proyecciones.filter((detProy) => {
              if (
                detalle.producto_liquido_id == detProy.producto_liquido_id &&
                moment(detalle.fecha).format("YYYY-MM-DD") ==
                  moment(detProy.fecha).format("YYYY-MM-DD")
              ) {
                this.operacionDetalles[index].id = detProy.id;
                this.operacionDetalles[index].crudo_ventas =
                  detProy.crudo_ventas;
                this.operacionDetalles[index].inventario = detProy.inventario;
                this.operacionDetalles[index].planta_externa =
                  detProy.planta_externa;
                this.operacionDetalles[index].regalias = detProy.regalias;
              }
            });
          });
        }
      }
    },

    calcularPromedios(sitio) {
      let promedio = {
        sitio_id: sitio.id,
        api: null,
        azufre: null,
      };
      let apiTotal = 0;
      let azufreTotal = 0;
      let count = 0;
      sitio.productos_liquidos.forEach((producto) => {
        apiTotal = apiTotal + parseFloat(producto.api);
        azufreTotal = azufreTotal + parseFloat(producto.azufre);
        count++;
      });
      promedio.api = count > 0 ? apiTotal / count : 0;
      promedio.azufre = count > 0 ? azufreTotal / count : 0;

      return promedio;
    },

    validarFechas() {
      if (this.form.fecha_ini) {
        const hoy = new Date();
        const fecha_ini = new Date(this.form.fecha_ini);
        fecha_ini.setDate(fecha_ini.getDate() + 1);

        if (hoy > fecha_ini) {
          this.form.fecha_ini = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial debe ser mayor o igual a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fecha_ini && this.form.fecha_fin) {
        var fecha_menor = new Date(this.form.fecha_ini);
        var fecha_mayor = new Date(this.form.fecha_fin);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fecha_ini && this.form.fecha_fin) {
        fecha_menor = new Date(this.form.fecha_ini).getTime();
        fecha_mayor = new Date(this.form.fecha_fin).getTime();
        var diff = fecha_mayor - fecha_menor;
        var fecha_limite = diff / (1000 * 60 * 60 * 24);
        if (fecha_limite > 31) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `Las fechas no pueden ser mayor a 31 dias...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      /* if (this.form.fecha_fin) {
        var hoy = new Date();
        const fecha_fin = new Date(this.form.fecha_fin);
        fecha_fin.setDate(fecha_fin.getDate());
        if (fecha_fin >= hoy) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha final debe ser menor o igual a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } */
    },

    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    async getBloques() {
      let me = this;
      await axios.get("/api/admin/bloques/lista").then((response) => {
        me.listasForms.bloques = response.data;
      });
    },

    async selectBloque() {
      this.form.bloque_id = null;
      if (this.bloque) {
        this.form.bloque_id = await this.bloque.id;
      }
    },

    back() {
      if (this.form.id && this.form.estado == 1) {
        this.$swal({
          title: "Esta seguro de volver?",
          text:
            "Se perderá todos los detalles de todos sitios del bloque " +
            this.bloque[0].nombre +
            " que no se hayan guardado!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Volver!",
        }).then(async (result) => {
          if (result.value) {
            return this.$router.replace("/Hidrocarburos/Proyecciones");
          }
        });
      } else {
        return this.$router.replace("/Hidrocarburos/Proyecciones");
      }
    },
  },

  async mounted() {
    this.getEstados();
    await this.getBloques();
    await this.init();
  },
};
</script>

<style>
.well {
  height: 100%;
  min-height: 20px;
  padding: 14px;
  margin: auto;
  margin-bottom: 10px;
  width: 100%;
  border: 2px solid #051650;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
</style>
